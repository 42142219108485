import React from "react";
import Pagination from "./Pagination";

import datafusionImg from "../images/datafusion.svg";
import transferImg from "../images/import.svg";
import compareImg from "../images/compare.svg";
import gstreakImg from "../images/gstreak.svg";
import throttle from "lodash/throttle";

const defaultProps = {};

const projectWaveSVG = (
  <svg id="svgWave" viewBox="0 0 100 10" className="Wave">
    <path fill="#FFFFFF" d="M0 10 V10 C32 -10 60 8 85 8 S95 2 100 5 V10z" />
  </svg>
);

const projects = [
  {
    img: datafusionImg,
    title: "Apache Datafusion",
    summary: `Regular contributor to apache ballista project which is part of apache arrow umbrella.`,
    link: "https://github.com/apache/arrow-datafusion",
  },
  {
    img: transferImg,
    title: "Sql to NoSQL Importer",
    summary: `SQLToNoSQLImporter is a Solr like data import handler to import Sql (MySQL, Oracle, PostgreSQL) data to NoSQL Systems (Mongodb, CouchDB, Elastic Search).`,
    link: "https://github.com/msathis/SQLToNoSQLImporter",
  },
  {
    img: compareImg,
    title: "Compare",
    summary: ` Compare is a folder comparision tool works using checksum and 
        has integrated mergly for seamless merging.`,
    link: "https://github.com/msathis/compare",
  },
  {
    img: gstreakImg,
    title: "Gstreak",
    summary: `Gstreak helps to maintain the git streak by scheduling the pushes.`,
    link: "https://github.com/msathis/gstreak",
  },
];

export default class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 1, pageSize: 3 };
  }

  componentDidMount() {
    this.updateDimensions();
    if (window.addEventListener) {
      window.addEventListener("resize", this.updateDimensions, false);
    } else if (window.attachEvent) {
      window.attachEvent("onresize", this.updateDimensions);
    }
  }

  componentWillUnmount() {
    if (window.removeEventListener) {
      window.removeEventListener(
        "resize",
        throttle(this.updateDimensions, 100),
        false
      );
    } else if (window.detachEvent) {
      window.detachEvent("onresize", throttle(this.updateDimensions, 100));
    } else {
      window["onresize"] = null;
    }
  }

  updateDimensions = () => {
    if (this.slider) {
      const width = this.slider.offsetWidth;

      this.setState({
        pageSize: width > 900 ? 3 : 1,
      });
    }
  };

  next = () => {
    this.setState({ page: this.state.page + 1 });
  };

  prev = () => {
    this.setState({ page: this.state.page - 1 });
  };

  render() {
    const { page, pageSize } = this.state;
    const start =
      page * pageSize < projects.length
        ? (page - 1) * pageSize
        : projects.length - pageSize;
    const end =
      page * pageSize < projects.length ? page * pageSize : projects.length;
    const data = projects.slice(start, end);

    return (
      <div className="Projects-wrapper" ref={(ref) => (this.slider = ref)}>
        {Array(pageSize)
          .fill()
          .map((x, i) => (
            <div className="Projects-item-wrapper">
              <div className="Projects-item">
                <img src={data[i].img} alt="Project Logo" />
                <span className="Project-title">{data[i].title}</span>
                {projectWaveSVG}
                <div className="Project-summary">{data[i].summary}</div>
                <a href={data[i].link} className="Project-link">
                  Read More
                </a>
              </div>
              {pageSize === 1 || i === 1 ? (
                <Pagination
                  next={this.next}
                  prev={this.prev}
                  pageSize={pageSize}
                  page={page}
                  data={projects}
                />
              ) : null}
            </div>
          ))}
      </div>
    );
  }
}

Projects.defaultProps = defaultProps;
