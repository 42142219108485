import React from "react";
import { Group } from "@vx/group";
import { scaleLinear } from "@vx/scale";
import { Point } from "@vx/point";
import { Line, LineRadial } from "@vx/shape";
import { max, min } from "d3-array";
import { AnnotationLabel } from "react-annotation";

const ANG = 360;

const calcAxis = (length) => {
  if (!length) return [];
  return new Array(length + 1)
    .fill(0)
    .map((v, i) => ({ angle: i * (ANG / length) }));
};

function calcPoints(length, radius) {
  const step = (Math.PI * 2) / length;
  return new Array(length).fill(0).map((v, i) => {
    return {
      x: radius * Math.sin(i * step),
      y: radius * Math.cos(i * step),
    };
  });
}

function calcCoordinates(data, scale, access) {
  const step = (Math.PI * 2) / data.length;
  const points = new Array(data.length).fill({});
  const pointStr = new Array(data.length + 1).fill("").reduce((res, v, i) => {
    if (i > data.length) return res;
    const x = scale(access(data[i - 1])) * Math.sin(i * step);
    const y = scale(access(data[i - 1])) * Math.cos(i * step);
    points[i - 1] = { x, y };
    return (res += `${x},${y} `);
  });

  points.str = pointStr;
  return points;
}

export default ({
  width,
  height,
  x = (d) => d.label,
  data,
  events = false,
  margin = { top: 80, left: 120, right: 120, bottom: 60 },
  levels = 5,
}) => {
  if (width < 10) return null;

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const webs = calcAxis(data.length);
  const radius = min([xMax, yMax]) / 2;
  const points = calcPoints(data.length, radius);

  const y = (d) => d.value;

  const rScale = scaleLinear({
    range: [0, Math.PI * 2],
    domain: [ANG, 0],
  });

  const yScale = scaleLinear({
    range: [0, radius],
    domain: [0, max(data, y)],
  });

  const polyPoints = calcCoordinates(data, yScale, y);

  return (
    <svg width={width} height={height}>
      <Group top={height / 2 - margin.top + 80} left={width / 2}>
        {[...new Array(levels)].map((v, i) => (
          <LineRadial
            data={webs}
            key={`web-${i}`}
            angle={(d) => rScale(d.angle)}
            radius={((i + 1) * radius) / levels}
            fill="none"
            stroke="rgba(255, 255, 255, 0.4)"
            strokeWidth={2}
            strokeOpacity={0.8}
            strokeLinecap="round"
          />
        ))}
        {[...new Array(data.length)].map((v, i) => (
          <Line
            key={`line-${i}`}
            from={new Point({ x: 0, y: 0 })}
            to={new Point({ x: points[i].x, y: points[i].y })}
            stroke="rgba(255, 255, 255, 0.4)"
          />
        ))}
        {[...new Array(data.length)].map((v, i) => {
          let align = points[i].x > 0 ? "bottom" : "right";
          const xDirection = points[i].x > 0 ? 1 : -1;
          const yDirection = points[i].x < 5 && points[i].y < 0 ? -1 : 1;
          const labelIndex = i - 1 < 0 ? data.length - 1 : i - 1;

          const yVal = width > 600 ? 30 : 10;
          const xVal = width > 600 ? 62 : 20;
          const fSize = width > 600 ? 16 : 12;

          return (
            <AnnotationLabel
              key={`annotation-${i}`}
              x={points[i].x}
              y={points[i].y}
              dy={yVal * yDirection}
              dx={xVal * xDirection}
              color={"rgba(0, 0, 0, 0.3)"}
              className="show-bg"
              note={{
                title: data[labelIndex].label,
                align,
                orientation: "leftRight",
                bgPadding: 20,
                padding: 5,
                fontSize: fSize,
                titleColor: "#F15946",
                lineType: "horizontal",
              }}
              connector={{ type: "line" }}
            />
          );
        })}
        <polygon
          points={polyPoints.str}
          fill="#5FAD56"
          fillOpacity="0.4"
          stroke="#ff9933"
          strokeWidth={1}
        />
        {polyPoints.map((v, i) => (
          <circle
            key={`point-${i}`}
            cx={v.x}
            cy={v.y}
            r={4}
            fill="#f5810c"
            className="dots"
          />
        ))}
      </Group>
    </svg>
  );
};
