import React from "react";
import { icons } from "eva-icons";

import "./Pagination.css";

const defaultProps = {
    page: 1,
    data: [],
    pageSize: 3
};

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { page, data, pageSize } = this.props;
        const leftEnable = page > 1;
        const rightEnable = page < Math.ceil(data.length / pageSize);

        return (
            <div className="Pagination">
                <i
                    onClick={leftEnable && this.props.prev}
                    style={{ background: leftEnable ? "#db0a5b" : "#ffffff" }}
                    className="Pagination-left"
                    dangerouslySetInnerHTML={{
                        __html: icons["chevron-left-outline"].toSvg({
                            width: 32,
                            height: 32,
                            fill: !leftEnable
                                ? "rgba(0, 0, 0, 0.2)"
                                : "rgba(255, 255, 255, 0.7)"
                        })
                    }}
                />
                <i
                    onClick={rightEnable && this.props.next}
                    style={{ background: rightEnable ? "#db0a5b" : "#ffffff" }}
                    className="Pagination-left"
                    dangerouslySetInnerHTML={{
                        __html: icons["chevron-right-outline"].toSvg({
                            width: 32,
                            height: 32,
                            fill: !rightEnable
                                ? "rgba(0, 0, 0, 0.2)"
                                : "rgba(255, 255, 255, 0.7)"
                        })
                    }}
                />
            </div>
        );
    }
}

Pagination.defaultProps = defaultProps;
