import React, { Component } from "react";
import { icons } from "eva-icons";

import logo from "../logo.png";

const arrowSVG = (
  <svg className="arrows">
    <text transform="rotate(90 18,18)" fill="#ffffff" x="15" y="0">
      Scroll
    </text>
    <path className="a0" d="M30 0 L30 58" />
    <path className="a1" d="M25 55 L30 62 L35 55" />
  </svg>
);

const heroWaveSVG = (
  <svg id="svgWave" viewBox="0 0 100 10" className="Wave">
    <path fill="#F9C22E" d="M-5 10 V10 C12 -5 30 8 55 8 S75 2 100 5 V10z" />
  </svg>
);

const menuIcon = icons["menu-2-outline"].toSvg({
  width: 32,
  height: 32,
  fill: "#fff",
});

const resumeIcon = icons["download"].toSvg({
  width: 32,
  height: 32,
  fill: "#fff",
  animation: "zoom",
});

export default class Header extends Component {
  render() {
    const { toggleMenu } = this.props;

    return (
      <header className="App-header">
        <div className="Bg-image">
          <lottie-player
            src="/bg_animation.json"
            speed="1"
            style={{ width: 800, height: 700 }}
            loop
            autoplay
          />
        </div>
        <div className="App-topbar">
          <img className="App-logo" src={logo} alt="App Logo" />
          <div className="Topbar-right">
            <span
              onClick={toggleMenu}
              className="App-menu-icon"
              dangerouslySetInnerHTML={{ __html: menuIcon }}
            />
          </div>
        </div>
        <div className="Intro-wrapper">
          <div className="Intro">
            <span className="Name-desc">I'm a fullstack developer</span>
            <div className="Name">Sathis &nbsp; Kumar</div>
            <div className="Resume-link-wrapper">
              <a href="/resume.pdf" className="Resume-link">
                <i
                  className="Resume-link-icon"
                  dangerouslySetInnerHTML={{
                    __html: resumeIcon,
                  }}
                />
                <span className="Resume-link-text">My Resume</span>
              </a>
            </div>
          </div>
        </div>
        {heroWaveSVG}
        {arrowSVG}
      </header>
    );
  }
}
